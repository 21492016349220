<template>
  <div class="importFile">
    <el-dialog
      :title="
        indexType == 0
          ? '导入单科总分'
          : indexType == 1
          ? '导入单科小题成绩'
          : '导入单科(含选项)成绩'
      "
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div v-if="indexType == 0" class="tips">
            <div class="tips-title">【提示】</div>
            1、若已有答题卡或录入过成绩的考生不可再次导入成绩。 <br />
            2、若所填数据任一项不合规则无法导入表格。<br />
            3、若无分值时则自动设为0分。<br />
            4、若分值超出题目分值上下限则禁止导入<br />
            5、新高考模式下，仅选考此科目的考生允许导入成绩。 <br />
            6、新高考模式下，成绩应为原始分。
          </div>
          <div v-if="indexType == 1 || indexType == 2" class="tips">
            <div class="tips-title">【提示】</div>
            1、若已有答题卡或录入过成绩的考生不可再次导入成绩。<br />
            2、若所填数据任一项不合规则无法导入表格。<br />
            3、若无分值时则自动设为0分。<br />
            4、若分值超出题目分值上下限则禁止导入<br />
            5、新高考模式下，仅选考此科目的考生允许导入成绩。 <br />
            6、新高考模式下，成绩应为原始分。
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <el-button type="text" :loading="btnDis" @click="downloadModel()">
                成绩导入模版.xlsx
              </el-button>
            </div>
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";
import { fileDownloadByUrl } from "@/core/util/util";
import { downloadTemplatev2 } from "@/core/api/exam/examscore";
export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      btnType: 0,
      indexType: 0,
      name: "",
      url: "",
      btnDis: false,
    };
  },
  created() {},
  methods: {
    downFile() {},
    downloadModel() {
      if (this.indexType == 0) {
        let subjectName = "";

        this.$parent.paperList.map((item) => {
          if (item.paperId == this.indexPaperId) {
            subjectName = item.subjectName;
          }
        });
        let url =
          "https://static.wtjy.com/analy/template/%E5%AF%BC%E5%85%A5%E6%80%BB%E6%88%90%E7%BB%A9%E6%A8%A1%E6%9D%BF.xlsx";
        let str =
          "成绩导入模板_" +
          this.$parent.examInformation.name +
          "_" +
          subjectName +
          "（总分）";
        this.name = str;
        this.url = url;
        fileDownloadByUrl(url, str);
      } else {
        let data = {
          paperId: this.$parent.indexPaperId,
          type: this.indexType,
        };
        this.btnDis = true;
        downloadTemplatev2(data)
          .then((res) => {
            this.btnDis = false;

            let { name, url } = res.data.data;
            fileDownloadByUrl(url, name);
          })
          .catch(() => {
            this.btnDis = false;
          });
      }
    },
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    upLoadSucc(data) {
      this.btnLoading = false;
      this.dialogVisible = false;
      this.data = data;
      setTimeout(() => {
        this.$parent.getDataList();
      }, 2000);
    },
    init(type) {
      this.url = "";
      this.indexType = type;
      this.dialogVisible = true;
      // this.downloadModel();
      this.$nextTick(() => {
        let url = "/exam/examstudentscore/importv2";
        let accept = ".xlsx,.xls";

        this.$refs.ElImportUpload.queryData = {
          paperId: this.$parent.indexPaperId,
          examId: this.$route.query.examId,
          type: type,
        };

        this.$refs.ElImportUpload.init(url, accept);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importFile {
}
</style>
