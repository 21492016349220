<template>
  <div class="ExchangeScores">
    <el-dialog
      title="交换考生成绩"
      :visible.sync="dialogVisible"
      width="578px"
      :close-on-click-modal="false"
    >
      <div class="model-box">
        <div>
          <el-input
            v-model="keyword1"
            placeholder="请输入考生考号"
            :debounce="500"
            @keyup.enter.native="handleClick(1)"
            @input="handleClick(1)"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="handleClick(1)"
            >
            </i>
          </el-input>
          <div class="student-box">
            <div class="student-header">考生1</div>
            <div class="student-msg">
              <template v-if="student1.studentId">
                <p>考号:{{ student1.studentExamId }}</p>
                <p>ID:{{ student1.studentId }}</p>
                <p>姓名:{{ student1.studentName }}</p>
                <p>行政班:{{ student1.classNum }}</p>
                <p>学校:{{ student1.schoolName }}</p>
                <p>成绩:{{ student1.score }}</p>
              </template>
              <template v-else><div>暂无信息</div></template>
            </div>
          </div>
        </div>
        <i class="el-icon-sort"></i>
        <div>
          <el-input
            v-model="keyword2"
            placeholder="请输入考生考号"
            :debounce="500"
            @keyup.enter.native="handleClick(2)"
            @input="handleClick(2)"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="handleClick(2)"
            >
            </i>
          </el-input>
          <div class="student-box">
            <div class="student-header">考生2</div>
            <div class="student-msg">
              <template v-if="student2.studentId">
                <p>考号:{{ student2.studentExamId }}</p>
                <p>ID:{{ student2.studentId }}</p>
                <p>姓名:{{ student2.studentName }}</p>
                <p>行政班:{{ student2.classNum }}</p>
                <p>学校:{{ student2.schoolName }}</p>
                <p>成绩:{{ student2.score }}</p>
              </template>
              <template v-else><div>暂无信息</div></template>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="!student1.studentId || !student2.studentId"
          @click="putScore()"
        >
          确认交换
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { studentInfov2, scoreExchange } from "@/core/api/exam/examstudentscore";
export default {
  name: "ExchangeScores",
  components: {},
  data() {
    return {
      dialogVisible: false,
      keyword1: "",
      keyword2: "",
      student1: {},
      student2: {},
    };
  },
  created() {},
  methods: {
    putScore() {
      let data = {
        examPaperId: this.$parent.indexPaperId,
        studentId1: this.student1.studentId,
        studentId2: this.student2.studentId,
      };
      this.btnLoading = true;
      scoreExchange(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: "成绩交换成功！",
            type: "success",
          });
          this.$parent.getDataList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    handleClick(type) {
      this["keyword" + type] = this["keyword" + type].trim();
      this.getStudentInfo(type);
    },

    getStudentInfo(type) {
      let data = {
        examPaperId: this.$parent.indexPaperId,
        examId: this.$route.query.examId,
      };
      data.studentExamId = this["keyword" + type];
      studentInfov2(data)
        .then((res) => {
          this["student" + type] = res.data.data;
          if (!res.data.data) {
            this["student" + type] = {};
          }
        })
        .catch(() => {
          this["student" + type] = {};
        });
    },
    init() {
      this.keyword1 = "";
      this.keyword2 = "";
      this.student1 = {};
      this.student2 = {};
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.ExchangeScores {
  .model-box {
    padding: 18px;
    border: 1px solid #dee0e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-icon-sort {
      font-size: 18px;
      transform: rotateZ(90deg);
    }
    .student-box {
      border: 1px solid #dee0e7;
      margin-top: 18px;
      .student-msg {
        padding: 18px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 216px;
        }
        p {
          margin-bottom: 18px;
          color: #0a1119;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .student-header {
        background: #f5f7fa;
        border-bottom: 1px solid #dee0e7;
        padding: 10px 18px;
        font-weight: 500;
        color: #0a1119;
      }
    }
  }
}
</style>
