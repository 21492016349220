import request from "@/core/services/axios";

export function getScoreLevelByLevel(query) {
  return request({
    url: "/exam/scorelevel/get/level",
    method: "get",
    params: query,
  });
}

export function scoreLevelReset(obj) {
  return request({
    url: "/exam/scorelevel/update",
    method: "post",
    data: obj,
  });
}

export function scoreLevelRevoke(query) {
  return request({
    url: "/exam/scorelevel/revoke",
    method: "get",
    params: query,
  });
}

// 联考-下载成绩导入模板
export function downloadTemplatev2(query) {
  return request({
    url: "/exam/examstudentscore/download/templatev2",
    method: "get",
    params: query,
  });
}

// 发布给教师-撤销发布
export function revoketeacher(query) {
  return request({
    url: "/exam/scorelevel/revoketeacher",
    method: "get",
    params: query,
  });
}

// 发布给教师-立即发布：
export function updateteacher(obj) {
  return request({
    url: "/exam/scorelevel/updateteacher",
    method: "post",
    data: obj,
  });
}
